import * as React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
// import Datoimagename from "../components/datoimagename"
const pageData = {
  titleClass: "notfound",
  title: "AVIELAN",
}

const NotFoundPage = () => {
  return (
    <Layout pagestyle={pageData}>
      <div className="pagetitle">
        <div className="fixedlogo"></div>
      </div>
      こちらのページは見つかりませんでした。
      <div>
      {/* <Datoimagename name="cumblk01.png" alt="test" folder="productsImg" /> */}
      </div>
    </Layout>
  )
}

export default NotFoundPage
